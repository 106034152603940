<template src="@/../../../templates/brand/components/templates/shop/checkout/Receipt.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'

export default {
  name: 'Receipt',
  beforeDestroy () {
    this.forgetOrder()
  },
  created: function () {
    // fires if user lands here without an order or reloads here 
    if (!this.order._id) this.$router.push({ path: '/checkout', params: { recycle: true } })
  },
  data () {
    return {
      tryAgain: false,
      txnStatus: null
    }
  },
  props: ['valid'],
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      order: ({ Order }) =>
        Order.order || {
          price: { taxes: {} },
          shipping: { address: {} },
          billing: { address: {} },
          items: [],
          txn: {}
        },
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    // order () {
    //   const _order = _.assign({}, this.neworder)
    //   _order.items = _.sortBy(['product.SKU'], _order.items)
    //   return _order
    // },
    shippingVals () {
      if (!this.order || !this.order.shippingService) return ''
      return _.assign({}, this.order.shippingService, {
        service: this.order.shippingService.rate.service
          ? this.order.shippingService.rate.service.name
          : this.order.shippingService.type
      })
    }
  },
  methods: {
    ...mapActions([
      // 'getOrder',
      // 'cancelOrder',
      // 'mergeTxn',
      // 'profileUpdate',
      // 'clearCartMemory',
      // 'removeTxn',
      // 'completeOrder',
      'forgetOrder'
    ]),
    // NB: temporary fixes order.items prices not binding adjusted unit and total
    // unitPrice (item) {
    //   if (item.product.adjustments.length && item.product.adjustments.some(a => a.volume)) {
    //     const _adj = item.product.adjustments.sort((a, b) => a.volume < b.volume)
    //     const _price = _adj.find(a => a.volume <= item.quantity)
    //     return _price ? _price.value : item.product.price
    //   } else {
    //     return item.product.price
    //   }
    // },
    // totalPrice (item) {
    //   let _unitPrice
    //   if (item.product.adjustments.length && item.product.adjustments.some(a => a.volume)) {
    //     const _adj = item.product.adjustments.sort((a, b) => a.volume < b.volume)
    //     const _price = _adj.find(a => a.volume <= item.quantity)
    //     _unitPrice = _price ? _price.value : item.product.price
    //   } else {
    //     _unitPrice = item.product.price
    //   }
    //   return _unitPrice * item.quantity
    // },
    currencyLocale: function (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    serviceDescription: function (service) {
      if (service.carrier === 'UPS') {
        return 'UPS: ' + !this.order.groupId
          ? this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
          : this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[
              this.order.orders[0].shippingService
            ]
      } else {
        // console.info(' ::: not UPS, get from shippingvals')
        return service.carrier + ': ' + this.shippingVals.service
      }
    }
  },
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/checkout/Receipt.css"></style>
